import axios from 'axios';
import {API_URL} from '../config/config';

class SearchService {
    async getSearchResultSubject(search_text, lang) {
        const params = {
            search_text: search_text,
            lang: lang
        };
        const res = await axios.post(`${API_URL}/search/search_text_subject`, params).catch((error) => {
            console.log(error)
        });
        return res;
    }

    async getSearchResultCategory(search_text, lang) {
        const params = {
            search_text: search_text,
            lang: lang
        };
        const res = await axios.post(`${API_URL}/search/search_text_category`, params).catch((error) => {
            console.log(error)
        });
        return res;
    }

    async getSearchResultTopic(search_text, lang) {
        const params = {
            search_text: search_text,
            lang: lang
        };
        const res = await axios.post(`${API_URL}/search/search_text_topic`, params).catch((error) => {
            console.log(error)
        });
        return res;
    }

    async getTopicBySearchingInVerse(account_id, search_text, lang) {
        const params = {
            account_id: account_id,
            search_text: search_text,
            lang: lang
        };
        const res = await axios.post(`${API_URL}/accounts/searchVerseText`, params).catch((error) => {
            console.log(error)
        });
        return res;
    }

      async getVerseByName(verse_name) {
        const params = {
            'verse_info': verse_name,
        };
        const res = await axios.post(`${API_URL}/verse/getByName`, params);
        return res;
    }

    async getSearchHistory(account_id) {
        const params = {
            'account_id': account_id
        };
        const res = await axios.post(`${API_URL}/accounts/getSearchHistory`, params)
            .catch((error) => {
                console.log(error.message || 'An error occurred');
            });
        return res;
    }

    async updateSearchHistory(account_id, search_text, topic_id, lang) {
        const params = {
            'account_id': account_id,
            'search_text': search_text,
            'topic_id': topic_id,
            'lang': lang
        };
        const res = await axios.post(`${API_URL}/accounts/updateSearchHistory`, params);
        return res;
    }

    async deleteSearchHistory(account_id, search_text, topic_id) {
        const params = {
            'account_id': account_id,
            'search_text': search_text,
            'topic_id': topic_id
        };
        const res = await axios.post(`${API_URL}/accounts/deleteSearchHistory`, params);
        return res;
    }

    async deleteAllSearchHistory(account_id) {
        const params = {
            'account_id': account_id,
        };
        const res = await axios.post(`${API_URL}/accounts/deleteAllSearchHistory`, params);
        return res;
    }

    async deleteMultipleSearchHistory(account_id, items) {
        const params = {
            'account_id': account_id,
            'items': items
        };
        const res = await axios.post(`${API_URL}/accounts/deleteMultipleSearchHistory`, params);
        return res;
    }

    async addBibleSearchHistory(account_id, search_text) {
        const params = {
            account_id: account_id,
            search_text: search_text
        };
        const res = await axios.post(`${API_URL}/accounts/searchBible`, params).catch((error) => {
            console.log(error)
        });
        return res;
    }

}

const searchServiceInstance = new SearchService();
export default searchServiceInstance;